<template>
	<div :class="['group', className('group-list-')]">
		<w-navTab titleText="队伍管理"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="head head-pict">
			<div class="head-picture">
				<img src="@/assets/img/test203.png" alt="" />
			</div>
			<div class="head-title" v-if="districtType() == 1">上饶市平安义警协会</div>
			<div class="head-title" v-if="districtType() == 2">{{districtName}}平安义警分会</div>
			<div class="head-bottom">
				<div class="bottom-box">
					<div class="bottom-box-text ">{{ total }}</div>
					<div class="bottom-box-title box-title">平安志愿者</div>
				</div>
				<div class="bottom-box">
					<div class="bottom-box-text">{{ totalYj }}</div>
					<div class="bottom-box-title box-title">义警总数</div>
				</div>
				<div class="bottom-box">
					<div class="bottom-box-text ">
						{{ serviceTime }}
						<span class="bottom-box-text-sub">时</span>
					</div>
					<div class="bottom-box-title box-title">总服务时长</div>
				</div>
			</div>
		</div>

		<div class="middle" v-if="districtType() == 1">
			<div class="middle-conner" v-for="(item, key) in plateList">
				<div :class="['middle-item', { sensitize: item.isShow }]" @click="onClickArrow(key,item)">
					<div class="middle-item-picture"><img :src="item.logo" alt="" /></div>
					<div class="middle-item-right">
						<div class="item-right-title" v-if="key == 0">市平安义警直属机构</div>
						<div class="item-right-title" v-else>{{ item.name }}平安义警分会</div>
						<div class="item-right-text">平安义警总人数：{{ item.total ? item.total : 0 }}人</div>
					</div>
					<div class="middle-item-icon">
						<img v-if="!item.isShow" src="@/assets/img/icon9.png" alt="" />
						<img v-else src="@/assets/img/icon205.png" alt="" />
					</div>
				</div>
				<div class="middle-bottom" v-show="item.isShow">
					<w-list class="list-view sign-in-scroll" :loading="loading" :finished="finished" :is-empty="isEmpty" >
						<div class="middle-bottom-item" v-for="vo in departmentList"
						@click="$router.push({ name: 'GroupDetail', params: { id: vo.department_id, district_id: item.id } })">
						<div class="item-left">{{ vo.title }}</div>
						<div class="item-right">
							{{ vo.total ? vo.total : 0 }}人
							<van-icon name="arrow" />
						</div>
				</div>
				</w-list>
			</div>
		</div>
	</div>

	<div class="cty-middle" v-if="districtType() == 2">
		<div class="cty-middle-item" v-for="item in plateList"
			@click="$router.push({ name: 'GroupDetail',params: { id: item.department_id } })">{{item.title}}</div>
	</div>
	</div>
</template>

<script>
	import Group from '@/api/group';
	import LoadingPage from '@/components/LoadingPage';
	import keepMiXin from '@/mixins/keep';
	import WList from '@/components/List';
	import ActionVendor from "@/vendor/action"

	const keep = keepMiXin(['GroupDetail']);

	export default {
		inject: ['className', 'districtType', 'themeColor'],
		name: 'Group',
		mixins: [keep],
		data() {
			return {
				total: '',
				totalYj: '',
				serviceTime: '',
				plateList: [],
				departmentList: [],
				loadingPage: true,
				districtName: "",
				loading: true,
				finished: false,
				isEmpty: false,
			};
		},
		created() {
			Group.plateGroup()
				.then(result => {
					this.districtName = result.data.district_name
					this.total = result.data.total;
					this.totalYj = result.data.total_yj;
					this.serviceTime = result.data.service_time;
					if (result.data.department_list) {
						this.plateList = result.data.department_list
					} else {
						let plateList = result.data.plateList;
						plateList.forEach((item, key) => {
							item.isShow = false;
						});
						this.plateList = plateList;
					}

					this.loadingPage = false;
				})
				.catch(error => {
					console.log(error);
				});
		},
		methods: {
			onClickArrow(index,item) {
				
				// if(item.id == 2){
				// 	return ActionVendor.jumpArea()
				// }
				
				this.loading = true
				this.departmentList = []
				this.plateList.forEach((item, key) => {
					if (index == key) {
						item.isShow = !item.isShow
					} else {
						item.isShow = false;
					}
				});
				if (this.plateList[index].isShow) {
					Group.listGroup({
							district_id: this.plateList[index].id,
						})
						.then(result => {
							this.departmentList = result.data
							this.loading = false
						}, error => {
							console.log(error)
						})
				}
			}
		},
		components: {
			LoadingPage,
			WList
		}
	};
</script>

<style lang="less" scoped>
	.group-list-city {
		.head-pict {
			background: url(../../assets/img/text203.png);
			background-size: 100%;

			.box-title {
				font-weight: bold;
				font-size: 20px;
				line-height: 29px;
				text-align: center;
				color: #fff;
			}

			.box-text {
				font-size: 20px;
				line-height: 24px;
				margin-top: 5px;
				color: #fff;
			}
		}
	}

	.group-list-district {
		.head-pict {
			background: url(../../assets/img/text204.png) !important;
			background-size: 100%;
		}
	}

	.group {
		.head {
			width: 100%;
			height: 229px;
			background: url(../../assets/img/text203.png);
			background-size: 100%;
			box-sizing: border-box;

			.head-picture {
				padding: 25px 0 15px;
				width: 80px;
				min-width: 80px;
				height: 80px;
				margin-left: 50%;
				transform: translateX(-50%);

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
			}

			.head-title {
				font-weight: bold;
				font-size: 20px;
				line-height: 29px;
				text-align: center;
				color: #fff;
			}

			.head-bottom {
				margin-top: 11px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 10px;

				.bottom-box {
					flex: 1;
					text-align: center;

					.bottom-box-title {
						font-size: 14px;
						line-height: 20px;
						color: #fff;
					}

					.bottom-box-text {
						font-size: 20px;
						line-height: 24px;
						color: #fff;
						margin-top: 5px;

						.bottom-box-text-sub {
							font-size: 14px;
						}
					}
				}
			}
		}

		.middle {
			padding: 10px;

			.middle-conner {
				margin-bottom: 10px;

				.sensitize {
					border-radius: 6px 6px 0 0 !important;

					.middle-item-right {
						.item-right-title {
							font-weight: bold;
							color: #3377ff !important;
						}
					}
				}

				.middle-item {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					background: #fff;
					padding: 10px;
					box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
					border-radius: 6px;
					position: relative;
					z-index: 2;

					.middle-item-picture {
						width: 60px;
						min-width: 60px;
						height: 60px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.middle-item-right {
						width: 250px;
						margin-left: 5px;

						.item-right-title {
							font-size: 18px;
							line-height: 26px;
							color: #333;
						}

						.item-right-text {
							font-size: 14px;
							line-height: 20px;
							color: #666;
							margin-top: 5px;
						}
					}

					.middle-item-icon {
						width: 14px;
						min-width: 14px;
						height: 14px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.middle-bottom {
					padding: 5px 20px;
					background-color: #fff;
					box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
					border-radius: 0 0 6px 6px;

					.middle-bottom-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 8px 0;
						border-bottom: 0.5px dashed #ddd;

						.item-left {
							font-weight: 400;
							font-size: 16px;
							line-height: 23px;
							color: #333;
						}

						.item-right {
							font-size: 14px;
							line-height: 20px;
							color: #666;
						}
					}

					.middle-bottom-item:last-child {
						border: 0;
					}
				}
			}
		}

		.cty-middle {
			padding: 10px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			&:after {
				content: "";
				width: 96px;
			}

			.cty-middle-item {
				width: 111px;
				font-size: 15px;
				line-height: 24px;
				text-align: center;
				color: #222;
				padding: 7px 0;
				box-sizing: border-box;
				background-color: #fff;
				border-radius: 6px;
				margin-top: 10px;
			}
		}
	}
</style>