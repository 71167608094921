import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 队伍管理
const plateGroup = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/plate/group', params)
}
// 义警总会简介
const chatGroup = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/plate/department/intro', params)
}
// 队伍管理二级列表
const listGroup = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/plate/group/department', params)
}

export default {
	plateGroup,
	chatGroup,
	listGroup
}
